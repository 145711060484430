<template>

    <v-card v-if="step == 1" style="max-width: 400px" class="ma-auto">
        <v-card-title>Wachtwoord hulp</v-card-title>
        <v-card-text>

            <p>Voer het e-mailadres in dat gekoppeld is aan uw account</p>

            <v-form v-model="validMail">
                <v-text-field v-model="model.email" label="email adres"></v-text-field>
                <v-btn block color="primary" :disabled="!validMail" @click="sendMailForgot()">
                    <span style="color:white">
                        Doorgaan
                    </span>
                </v-btn>
            </v-form>

        </v-card-text>

        <v-card-text v-if="errors.length > 0">
        Uw e-mailadres is niet bekend bij ons</v-card-text>

 


    </v-card>

    <v-card v-else-if="step == 2" style="max-width: 400px" class="ma-auto">
        <v-card-title>Verificatie vereist</v-card-title>

        <v-card-text>

            <p>Voer deze verificatiestap uit om door te gaan. We hebben een code verstuurd naar {{ model.email }}. Voer
                de code hieronder in.</p>

            <div class="ma-auto position-relative" style="max-width: 300px">

                <b>Code invoeren</b>
                <v-otp-input v-model="otp.model" :disabled="otp.loading" @finish="onFinish"></v-otp-input>
                <v-overlay absolute :value="otp.loading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-overlay>
            </div>
        
            <div class="text--caption">Type of kopieer/plak de code uit de email.</div>
            <br>
<p>Het sturen van de code kan 1-2 minuten duren. Geen mail ontvangen? Kijk dan of de mail in de ongewenste mail terecht is gekomen.</p>
            <v-card-text v-if="errors.length > 0">
                De ingegeven code is niet correct. Toets nogmaals de code in of klik <a href="#"
                    @click="back2Begin()">hier</a> om opnieuw aan te melden.</v-card-text>
        </v-card-text>



    </v-card>
    <v-card v-else-if="step == 3" style="max-width: 400px" class="ma-auto">
        <v-card-title>Een nieuw wachtwoord aanmaken</v-card-title>
        <v-card-text>
            <p>
                Vul de onderstaande gegevens in om een nieuw wachtwoord aan te maken.
            </p>

            <p>
                <v-form v-model="validChange">
                    <v-text-field autocomplete="new-password" v-model="modelForgot.password"
                        :append-icon="showww ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.passwordRule]"
                        :type="showww ? 'text' : 'password'" label="Wachtwoord" @click:append="showww = !showww"
                        required></v-text-field>
                    <v-text-field autocomplete="new-password" v-model="modelForgot.repeat"
                        :append-icon="showhww ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required]"
                        @click:append="showhww = !showhww" :type="showhww ? 'text' : 'password'"
                        label="herhaal wachtwoord" required></v-text-field>

                    <v-btn :disabled="!(validChange && (modelForgot.password == modelForgot.repeat))" class="mr-4"
                        @click="forgot" color="primary">Registreer

                    </v-btn>
                </v-form>
            </p>


            <p>
            <ol>
                <li>
                    <b>Wachtwoord:</b>
                    <p>Kies een sterk wachtwoord dat voldoet aan de volgende criteria:
                    <ul>
                        <li>
                            Minimaal 6 tekens lang,
                        </li>
                        <li>Bevat tenminste één letter, één hoofdletter, één cijfer en één speciaal teken (bijv. @,
                            #,
                            !, %)</li>
                    </ul>

                    </p>
                </li>
                <li>
                    <b>Herhaal wachtwoord</b>
                    <p>Voer het wachtwoord opnieuw in om de registratie te voltooien.</p>
                </li>
            </ol>
            </p>

        </v-card-text>

    </v-card>



</template>


<script>

import { userService } from "../../api/server/services/userService";
import client from "api-client";

export default {
    name: "ForgotPassword",
    data: () => ({
        model: {},
        modelForgot: {},
        validMail: false,
        validChange: false,
        step: 1,
        otp: {
            model: '',
            loading: false
        },
        rules: {
            required: (v) => !!v || "Waarde is verplicht",
            emailRule: (v) => /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) || "Geen geldig emailadres",
            passwordRule: (v) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/.test(v) || "Wachtwoord is nog niet sterk genoeg",
        },
        errors: [],
        showww: false,
        showhww: false,

    }),
    methods: {
        onFinish(rsp) {
            this.checkOtp(rsp)
        },
        back2Begin() {
            this.model = {}
            this.errors = []
            this.validMail = false
            this.otp.model = ''
            this.expectedOtp = ''
            this.step = 1
        },
        sendMailForgot() {
            this.errors = []
            client.otpStep1(this.model, 'forgot').then((res) => {
                if (res.result == 'ok') {
                    this.step = 2
                } else {
                    this.errors.push("Gebruiker komt niet voor")
                }

            });
        },
        checkOtp(otp) {
            this.errors = []
            client.otpStep2(otp, 'forgot').then((res) => {
                if (res.id != null) {
                    this.modelForgot.id = res.id
                    this.step = 3
                } else {
                    this.errors.push("Code klopt niet")
                }
            })
        },
        forgot() {

            const payload = { id: this.modelForgot.id, password: this.modelForgot.password }

            client.forgot(payload).then((res) => {

                userService.setToken(res.token)
                this.$router.push("closed")
            })
        }
    }
}
</script>